<template>
	<div class="home">
		<div class="zxyj-content-box">
			<div class="banner-content">
				<div class="banner" v-if="banner.length == 0">
					<el-carousel :height="elCarousel">
						<el-carousel-item>
							<img style="height: 400px;" src="../../assets/images/home/banner.png" alt="加载失败" />
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="banner" v-else>
					<el-carousel :height="elCarousel">
						<el-carousel-item v-for="item in banner" :key="item.index">
							<img style="height: 400px;" :src="item.bannerUrl" alt="加载失败" />
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>

		<div class="content-box-gray">
			<div class="xzyj-content">
				<div>
					<div class="xst-title">产品介绍</div>
					<div class="xst-title-bottom">
						<div class="bottom-img"><img src="../../assets/images/home/homepage-line.png"/></div>
					</div>
				</div>
				<div class="xst-product">
					<div class="xst-product-ctrl">
						<div :class="des_selected==1? 'xst-ctrl xst-ctrl-selected' : 'xst-ctrl'" @click="changeImg(1)" style="border-radius: 2px 0px 0px 0px;">
							<img :src="des_selected==1 ? image1_selected : image1"/>
							<span>远程操控系统</span>
							<div class="xst-ctrl-bottom" v-show="des_selected==1"><img src="../../assets/images/home/三角形.png"/></div>
						</div>
						<div :class="des_selected==2? 'xst-ctrl xst-ctrl-selected' : 'xst-ctrl'" @click="changeImg(2)">
							<img :src="des_selected==2 ? image2_selected : image2"/>
							<span>安全监管系统</span>
							<div class="xst-ctrl-bottom" v-show="des_selected==2"><img src="../../assets/images/home/三角形.png"/></div>
						</div>
						<div :class="des_selected==3? 'xst-ctrl xst-ctrl-selected' : 'xst-ctrl'" @click="changeImg(3)">
							<img :src="des_selected==3 ? image3_selected : image3"/>
							<span>激光雷达系统</span>
							<div class="xst-ctrl-bottom" v-show="des_selected==3"><img src="../../assets/images/home/三角形.png"/></div>
						</div>
						<div :class="des_selected==4? 'xst-ctrl xst-ctrl-selected' : 'xst-ctrl'" @click="changeImg(4)">
							<img :src="des_selected==4 ? image4_selected : image4"/>
							<span>视频监控系统</span>
							<div class="xst-ctrl-bottom" v-show="des_selected==4"><img src="../../assets/images/home/三角形.png"/></div>
						</div>
						<div :class="des_selected==5? 'xst-ctrl xst-ctrl-selected' : 'xst-ctrl'" @click="changeImg(5)" style="border-radius: 0px 2px 0px 0px;">
							<img :src="des_selected==5 ? image3_selected : image5"/>
							<span>NB IOT系统</span>
							<div class="xst-ctrl-bottom" v-show="des_selected==5"><img src="../../assets/images/home/三角形.png"/></div>
						</div>
					</div>
					<div :class="product_bakimg">
						<div class="xst-product-content-top">{{tabTitle}}</div>
						<div class="xst-product-content-main">{{tabSub}}</div>
					</div>
				</div>
				<div style="display: flex;justify-content: center;margin-top: 20px;">
					<div class="xst-link-div">
						<a class="xst-link-mainPage" href="/products/index.html"  onclick="window.open(this.href);return false">查看详情</a>
					</div>
				</div>
			</div>
		</div>
		<!-- 项目案例 -->
		<div class="project">
			<div class="xzyj-content position-relative">
				<div>
					<div class="xst-title">项目案例</div>
					<div class="xst-title-bottom">
						<div class="bottom-img"><img src="../../assets/images/home/homepage-line.png"/></div>
					</div>
				</div>
				<div class="xst-project">
					<div class="xst-project-content">
						<div class="xst-project-back" style="left:0;"></div>
						<div class="xst-project-list" style="left: 68px;">
							<a class="xst-project-item xst-left-margin" v-for="(item, i) in cases" :key="i"  v-on:mouseenter="showDialog(i,item)" v-on:mouseleave="hideDialog(i,item)" :href="['/caseDetail/index.html?id='+item.id+'&type=case']" onclick="window.open(this.href);return false">
								<img class="xst-project-img" :src="item.image" alt="加载失败" />
								<div class="xst-project-title-back" v-if="!ishow || i !=current"></div>
								<div class="xst-project-title" v-if="!ishow || i !=current">{{ item.title }}</div>
								<!--弹出层-->
								<div style="display: flex; flex-direction: column;" class="handleDialog" v-if="ishow && i==current">
									<div class="content">
										<div class="content-title font-overflow-line">{{ item.title }}</div>
										<div class="content-sub-title font-overflow-lines">{{ item.subTitle }}</div>
									</div>
									<a class="content-button" :href="['/caseDetail/index.html?id='+item.id+'&type=case']" onclick="window.open(this.href);return false">查看详情</a>
								</div>
							</a>
						</div>
					</div>
					<div class="xst-project-content">
						<div class="xst-project-back" style="right: 0;"></div>
						<div class="xst-project-list" style="left:0;">
							<a class="xst-project-item xst-right-margin" v-for="(item, i) in cases2" :key="i" v-on:mouseenter="showDialog(i+4,item)" v-on:mouseleave="hideDialog(i+4,item)" :href="['/caseDetail/index.html?id='+item.id+'&type=case']" onclick="window.open(this.href);return false">
								<img class="xst-project-img" :src="item.image" alt="加载失败" />
								<div class="xst-project-title-back" v-if="!ishow || i+4 !=current"></div>
								<div class="xst-project-title" v-if="!ishow || i+4 !=current">{{ item.title }}</div>
								<!--弹出层-->
								<div style="display: flex; flex-direction: column;" class="handleDialog" v-if="ishow && i+4==current">
									<div class="content">
										<div class="content-title font-overflow-line">{{ item.title }}</div>
										<div class="content-sub-title font-overflow-lines">{{ item.subTitle }}</div>
									</div>
									<a class="content-button" :href="['/caseDetail/index.html?id='+item.id+'&type=case']" onclick="window.open(this.href);return false">查看详情</a>
								</div>
							</a>
						</div>
					</div>
				</div>
				<div style="display: flex;justify-content: center;margin-top: 20px;">
					<div class="xst-link-div">
						<a class="xst-link-mainPage" href="/case/index.html"  onclick="window.open(this.href);return false">查看更多</a>
					</div>
				</div>
				
			</div>
		</div>
		<!-- 加工工厂 -->
		<div class="factory">
			<div class="xzyj-content position-relative">
				<div>
					<div class="xst-title">加工工厂</div>
					<div class="xst-title-bottom">
						<div class="bottom-img"><img src="../../assets/images/home/homepage-line.png"/></div>
					</div>
				</div>
				<div class="xst-carousel" style="position: relative;z-index: 1;display: flex;align-items: center;">
					<div :class="i!=1 ? 'mediumDftH medium' : 'mediumSeltH medium'" v-for="(item, i) in factorys" :key="i" @click="openFactoryDetail(['/factoryDetail/index.html?id='+item.id+'&type=factory'])">
						<div class=""><img :class="i!=1 ? 'mediumDefaultImg' : 'mediumSelectImg'" :src="item.image" alt="加载失败" /></div>
						<div  style="height: 150px;width: 100%;padding: 16px 30px 30px 30px;text-align: center;">
							<div class="xst-factory-title">{{ item.title }}</div>
							<div style="width:30px;height:1px;background: #122A88;left: 0;right: 0;margin: auto;"></div>
							<div class="xst-factory-subTitle font-overflow-line">{{ item.subTitle }}</div>
						</div>
					</div>
					<!-- <el-carousel :interval="3000" type="card" height="550px" @change="carouselChange" :autoplay="false">
						<el-carousel-item  v-for="(item, i) in factorys" :key="i" >
							<div class="medium" @click="openFactoryDetail(['/factoryDetail/index.html?id='+item.id+'&type=factory'])">
								<img :src="item.image" alt="加载失败" />
								<div>
									<div class="xst-factory-title">—{{ item.title }}—</div>
									<div class="xst-factory-subTitle font-overflow-line">{{ item.subTitle }}</div>
								</div>
							</div>
							<a class="medium" :href="['/factoryDetail/index.html?id='+item.id+'&type=factory']" onclick="window.open(this.href);return false">
							 <img :src="item.image" alt="加载失败" />
							</a>
						</el-carousel-item>
					</el-carousel> -->
				</div>
			</div>
			<div class="xst-factory-back">
				<div style="display: flex;justify-content: center;">
					<div class="xst-link-div" style="margin-top: 60px;border: 1px solid #f6f6f6;">
						<a class="xst-link-mainPage" style="color: #ffffff;" href="/factory/index.html"  onclick="window.open(this.href);return false">查看更多</a>
					</div>
				</div>
			</div>
		</div>
		<!-- 新闻中心 -->
		<div class="news">
			<div class="xzyj-content position-relative">
				<div>
					<div class="xst-title">聚焦鑫昇腾</div>
					<div class="xst-title-bottom">
						<div class="bottom-img"><img src="../../assets/images/home/homepage-line.png"/></div>
					</div>
				</div>
				<div class="news-content" style="display: flex;">
					<div class="news-left">
						<a v-if="leftNews.id" :href="['/newsDetail/index.html?id='+leftNews.id+'&type=news']" onclick="window.open(this.href);return false">
							<div style="width: 460px; height: 330px;">
								<img style="height: 100%;width: 100%;" :src="leftNews.image" alt="加载失败" />
							</div>
							<div style="margin-top: 15px;line-height: 30px;">
								<div class="news-title" style="">{{leftNews.title}}</div>
								<div class="news-time">{{leftNews.date}}</div>
								<div class="news-des">{{leftNews.subTitle}}</div>
							</div>
						</a>
					</div>
					<div class="news-right">
						<a class="news-right-content" v-for="(item, i) in news" :key="i" :href="['/newsDetail/index.html?id='+item.id+'&type=news']" onclick="window.open(this.href);return false">
							<div class="content-body border">
								<div class="news-right-content-left" style="">
									<div style="display: flex; justify-content: space-between;line-height: 50px; align-items: center;margin-bottom: 8px;">
										<div class="news-title" style="">{{item.title}}</div>
										<div class="news-time">{{item.date}}</div>
									</div>
									<div class="news-des">{{item.subTitle}}</div>
								</div>
							</div>
						</a>
					</div>
				</div>
				<div style="display: flex;justify-content: center;">
					<div class="xst-link-div">
						<a class="xst-link-mainPage" href="/news/index.html"  onclick="window.open(this.href);return false">查看更多</a>
					</div>
				</div>
			</div>
		</div>
		<!-- 公司介绍 -->
		<div class="guanyu">
			<div class="xzyj-content position-relative">
				<div>
					<div class="xst-title">关于鑫昇腾</div>
					<div class="xst-title-bottom">
						<div class="bottom-img"><img src="../../assets/images/home/homepage-line.png"/></div>
					</div>
				</div>
				<div style="position: relative;margin-top: 40px;">
					<!-- <img src="../../assets/images/home/homepage-ico-信号备份.png" style="position:absolute;width:126px;z-index: 10;left: 76px;top: -78px;"/>
					<img src="../../assets/images/home/homepage-ico-信号备份2.png"  style="position:absolute;width:126px;z-index: 10;right: 74px;top: -76px;"/> -->
					<div class="xst-box1">
						
					</div>
					<div class="xst-box2"></div>
					<div class="xst-box3"></div>
					<div class="xst-box4">
						<img src="../../assets/images/home/homepage-img-yishengteng.png"/>
					</div>
				</div>

			</div>
			<div class="xst-box5">
				<div class="company-profile">江苏鑫昇腾科技发展股份有限公司（www.ixzyj.com）成立于2019年9月，总部位于江苏·南京，公司纵深于智能爬架行业，成立2年已搭建了遍布全国的销售和服务网络，公司为客户提供智能爬架租赁、销售、运营、监管、技术服务等一站式解决方案和服务保障，通过优质的服务、良好的口碑，先后获得了绿地集团、碧桂园、中国铁建、中交集团、中建集团、中天建设、江苏省建等多家大中型房地产开发企业及建筑施工单位的青睐和认可。</div>
				<div class="xst-link-div" style="border: 1px solid #FFF; color: #FFF;">
					<a class="xst-link-mainPage" style="color: #FFF;" href="/about/index.html"  onclick="window.open(this.href);return false">查看详情</a>
				</div>
			</div>
		</div>
		<!-- 主要客户 -->
		<div class="customer-body">
			<div class="xzyj-content">
				<div>
					<div class="xst-title">合作伙伴</div>
					<div class="xst-title-bottom">
						<div class="bottom-img"><img src="../../assets/images/home/homepage-line.png"/></div>
					</div>
				</div>
				<div class="customer-list">
					<img src="../../assets/images/home/homepage-cooperation.png">
				</div>
			</div>
		</div>

		<!-- 联系我们 -->
		<div class="contact" v-show="false">
			<div class="title">
				<div class="cn">联系我们</div>
				<div class="en">CONTCAT US</div>
				<div class="text">
					生产安全耐用的附着式升降脚手架是我们一项的宗旨<br />用户的满意就是我们的品牌
				</div>
			</div>
			<div class="form">
				<div class="form_lf">
					<div class="img_form1">
						<img src="@/assets/images/home/form1.png" alt="" />
					</div>
					<form action="" class="submit">
						<textarea name="" id="" cols="30" rows="6" placeholder="请输入留言内容" v-model="form.content" style="resize:none;"></textarea>
						<input type="text" placeholder="请输入姓名" v-model="form.name" />
						<input type="text" placeholder="请输入手机号" v-model="form.phone" />
						<button @click="subLeaving">提交</button>
					</form>
				</div>
				<div class="form_rt">
					<div class="img_form2">
						<img src="@/assets/images/home/form2.png" alt="" />
					</div>
					<p>江苏鑫昇腾科技发展股份有限公司</p>
					<p>电话：180-2111-2855</p>
					<p>E-mail：40358771@qq.com</p>
					<p>网址：鑫昇腾科技.网址</p>
					<p>地址：南京市长安镇乌沙社区新乐路21号B栋306</p>
					<button @click="getNews">了解更多</button>
				</div>
			</div>
			<div class="text">中国智能爬架智慧创新领导企业</div>
			<!-- 弹窗 -->
			<div class="tanchuang" v-if="tanchuang">
				<img src="@/assets/images/aa.png" alt="" />

				<div class="sub" @click="tanchuang = false">×</div>
				<div class="suba">
					江苏鑫昇腾科技发展股份有限公司<br />
					联系人：李先生<br />
					电话：180-2111-2855
				</div>
				<div class="subb">
					<button class="a" @click="tanchuang = false">
						稍后再说
					</button>
					<!-- <button class="b">现在咨询</button> -->
				</div>
			</div>
		</div>
		<div v-show="xuanIcon"  class="xuan-icon" style="cursor: pointer;" @click="openXuanfu">
			
		</div>
		<div class="xuanFu" v-show="xuanflag">
			<div style="display: flex;flex-direction: row;">
				<div style="display: flex;width: 40%;"></div>
				<div style="display: flex;flex-direction: column;justify-content: center;margin-top: 50px;">
					<div style="display: flex;flex-direction: column; color: white;font-size: 16px;">
						<div style="display: flex; ">
							江苏鑫昇腾科技发展股份有限公司
						</div>
						<div style="display: flex;">
							联系人：刘先生
						</div>
						<div style="display: flex;">
							电话：180-6880-3281
						</div>
					</div>
					<div style="display: flex;margin-top: 20px;justify-content: flex-end;justify-content: space-between;width: 80%;align-self: flex-end;">
						<div style="display: flex; align-items: center;justify-content: center;cursor: pointer;" :class="xuanfu1" @click="xuanfuClick(1)">
							稍后再说
						</div>
						<div style="display: flex; align-items: center;justify-content: center;cursor: pointer;" :class="xuanfu2" @click="xuanfuClick(2)">
							现在咨询
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="xuanfuForm" v-show="xuanfuFrom">
			<div style="display: flex;flex-direction: column;align-items: center;">
				<div style="display: flex; background-color: #00a0e9;align-items: center;justify-content: space-between;width: 100%;">
					<div style="display: flex;color: white;font-size: 24px;height: 50px;align-items: center;width: 150px;justify-content: center;">
						请您留言
					</div>
					<div style="display: flex;background-color: white;width: 30px;height: 5px;margin-right: 10px;" @click="closeFrom">

					</div>
				</div>
				<div style="display: flex; width: 100%;align-items: center;flex-direction: column;border: 3px solid #fff;height: 150px;">
					<div style="display: flex;flex-direction: column;width: 90%;justify-content: space-around;height: 120px;margin-top: 20px;">
						<div style="display: flex; font-size: 24px;">
							江苏鑫昇腾科技发展股份有限公司
						</div>
						<div style="display: flex;font-size: 20px;">
							180-6880-3281
						</div>
					</div>
				</div>
				<div style="display: flex; width: 90%;margin-top: 20px;">
					<el-input v-model="form.content" type="textarea" :rows="6"  resize="none" placeholder="请在此输入留言内容，我们会尽快与您联系。(必填)" />
				</div>
				<div style="display: flex;width: 90%;margin-top: 20px;">
					<el-input v-model="form.name" type="text"  placeholder="姓名" :rows="1"  resize="none"/>
				</div>
				<div style="display: flex;width: 90%;margin-top: 20px;">
					<el-input v-model="form.phone"  type="text" placeholder="电话" :rows="1"  resize="none"/>
				</div>
				<div style="display: flex;width: 90%;margin-top: 20px;">
					<el-input v-model="form.addr" type="text" placeholder="地址" :rows="1"  resize="none"/>
				</div>
				<div style="display: flex; height:80px; justify-content: center;align-items: center;">
					<div style="width: 100px;height: 30px;background-color: #00a0e9;color: white;align-items: center;display: flex;align-items: center;justify-content: center;cursor: pointer;" @click="subLeaving">发送</div>
				</div>
			</div>

		</div>
		<div style="height: 100px"></div>
	</div>

</template>

<script>
	import http from "@/common/utils/Http";
	import error from "@/common/utils/Error";
	export default {
		metaInfo: {
			title:"智能爬架工程服务-鑫昇腾",
			meta:[
				{name:"keywords", content: "爬架,建筑爬架,智能爬架,全钢爬架,鑫昇腾"},
				{name:"description", content: "江苏鑫昇腾科技发展股份有限公司专注于建筑爬架的设计、制造和专业承包,提供爬架租赁、销售、运营、安全监管、技术服务等一站式解决方案。"}
			]
		},
		data() {
			return {
				xuanIcon:false,
				xuanflag:true,
				xuanfuFrom:false,
				news: [],
				cases: [], // 第一栏项目案例
				cases2: [], // 第二栏项目案例
				factorys: [],
				banner: [],
				leftNews: {},
				ishow: false,
				isIcon: false,
				
				xuanfu1: "btn btn-null",
				xuanfu2: "btn btn-null",
				current: 0, //当前操作按钮
				params:{},
				form: {
					content: "",
					name: "",
					phone: "",
					addr:""
				},
				tanchuang: false,
				elCarousel: "400px",
				currentFactory: {
					title: "",
					subTitle: ""
				},
				des_selected: 1,
				image1:require('@/assets/images/home/homage-ico-远程操控系统.png'),
				image1_selected:require('@/assets/images/home/homage-ico-远程操控系统-selected.png'),
				image2:require('@/assets/images/home/homage-ico-安全监控系统.png'),
				image2_selected:require('@/assets/images/home/homage-ico-安全监控系统-selected.png'),
				image3:require('@/assets/images/home/homage-ico-激光雷达系统.png'),
				image3_selected:require('@/assets/images/home/homage-ico-激光雷达系统-selected.png'),
				image4:require('@/assets/images/home/homage-ico-视频监控系统.png'),
				image4_selected:require('@/assets/images/home/homage-ico-视频监控系统-selected.png'),
				image5:require('@/assets/images/home/homage-ico-NBIOT系统.png'),
				image5_selected:require('@/assets/images/home/homage-ico-NBIOT系统-selected.png'),
				tabTitle: '远程操控系统',
				tabSub: '采集电箱当前工作状态，展示当前位置是否正常工作，远程操控架体运行',
				product_bakimg: 'xst-product-bakimg1 xst-product-content'
			};
		},
		activated() {
			this.getNews();
			this.getCases();
			this.getFactory();
			this.getBanner();
		},
		methods: {
			openFactoryDetail(factoryDetailUrl) {
				console.log(factoryDetailUrl);
				window.open(factoryDetailUrl);
			},
			changeImg(curr){
				this.des_selected=curr;
				if(curr==1){
					this.tabTitle="远程操控系统";
					this.tabSub="采集架体工作楼层及各机位数据，远程操控架体运行";
					this.product_bakimg='xst-product-bakimg1 xst-product-content';
				}
				if(curr==2){
					this.tabTitle="安全监管系统";
					this.tabSub="采集架体承重数据及架体平整度数据，超过设定值，该点位报警。平台展示警戒值并同步短信通知现场项目管理人";
					this.product_bakimg='xst-product-bakimg2 xst-product-content';
					
				}
				if(curr==3){
					this.tabTitle="激光雷达系统";
					this.tabSub="采集架体当前海拔高度，自动展示架体当前工作楼层";
					this.product_bakimg='xst-product-bakimg3 xst-product-content';
				}
				if(curr==4){
					this.tabTitle="视频监控系统";
					this.tabSub="现场监控，预防危险操作和确定设备安全状况";
					this.product_bakimg='xst-product-bakimg4 xst-product-content';
				}
				if(curr==5){
					this.tabTitle="NB IOT系统";
					this.tabSub="架体传感器采集数据经过自研芯片处理，汇入运营商的物联网专网，经IOT平台与用户的平台进行数据交互";
					this.product_bakimg='xst-product-bakimg5 xst-product-content';
				}
			},
			openXuanfu(){
				this.xuanflag=true;
				this.xuanIcon=false;
			},
			closeFrom(){
				this.xuanfuFrom=false;
				this.xuanIcon=true;
			},
			xuanfuClick(i) {
				if (1 == i) {
					this.xuanfu1 = "btn btn-current"
					this.xuanfu2 = "btn btn-null"
					this.xuanflag=false
					this.xuanIcon=true
					
				}
				if (2 == i) {
					this.xuanfu2 = "btn btn-current"
					this.xuanfu1 = "btn btn-null"
					this.xuanfuFrom=true;
					this.xuanflag=false
				}
			},
			showDialog(index, item) {

				console.log(index, item)
				this.ishow = true;
				this.current = index;
			},
			//隐藏蒙层
			hideDialog(index, item) {
				console.log(index, item)
				this.ishow = false;
				this.current = null;
			},
			getNews() {
				let params = this.params;
				params.header='1';
				http.api.get("/site/news/list",{params}).then((res) => {
					this.news = this.changeArr(res.data.rows);
				});
			},
			getCases() {
				let params = this.params;
				params.header='1';
				http.api.get("/site/case/list",{params}).then((res) => {
					// this.cases = 
					this.changeCase(res.data.rows);
				});
			},
			getFactory() {
				let params = this.params;
				params.header='1';
				http.api.get("/site/factory/list",{params}).then((res) => {
					this.factorys = this.changeFactory(res.data.rows);
				});
			},
			getBanner() {
				http.api.get("/site/banner/list").then((res) => {
					this.banner = res.data.rows;
				});
			},
			// 打开详情页
			detail(type, id) {
				this.$router.push({
					path: "newsDetail",
					query: {
						type: type,
						id: id
					},
				});
			},
			// 提交留言
			subLeaving() {
				// let leaving = this.form
				http.api
					.post("/site/leaving", this.form)
					.then((res) => {
						this.xuanfuFrom=false
						this.factorys = this.changeFactory(res.data.rows);
						
					})
					.catch((err) => {
						error.httpErrorHandler(err);
					});
			},
			// 转换案例
			changeCase(arr) {
				let newArr = [];
				let newArr2 = [];
				arr.forEach((row, i) => {
					let sub = {};
					sub.image = row.caseUrl;
					sub.id = row.siteCaseId;
					sub.title = row.caseTitle;
					sub.subTitle = row.caseSubtitle;
					i < 4 && newArr.push(sub); //第一栏
					i <8 && i >= 4 && newArr2.push(sub); //第二栏
				});
				this.cases = newArr;
				this.cases2 = newArr2;
			},
			changeFactory(arr) {
				let newArr = [];
				arr.forEach((row, i) => {
					let sub = {};
					sub.image = row.factoryUrl;
					sub.id = row.siteFactoryId;
					sub.title = row.factoryName;
					sub.subTitle = row.factoryIntroduction
					i < 3 && newArr.push(sub); //首页展示4条
				});
				return newArr;
			},
			// 转换新闻
			changeArr(arr) {
				let that = this;
				let newArr = [];
				arr.forEach((row, i) => {
					let sub = {};
					const date = row.createTime.split("-");
					const day = date[2].split(" ")[0];
					sub.day = day;
					sub.month = date[1];
					sub.year = date[0];
					sub.date = sub.year + "." + sub.month + "." + sub.day;
					sub.title = row.newsTitle;
					sub.image = row.newsUrl;
					sub.id = row.siteNewsId;
					sub.subTitle = row.newsSubtitle;
					if (i < 1) {
						that.leftNews = sub;
					}

					i < 5 && i > 0 && newArr.push(sub); //首页展示3条
				});
				return newArr;
			},
			carouselChange(index,i) { // 幻灯片切换
				console.log(i)
				this.currentFactory = this.factorys[index]
			}
		},
	};
</script>

<style lang="scss" scoped>
	// 公共样式开始---------------------------------
	.banner-content {
		margin-top: 68px;
		position: relative;
		height: 400px;
		width: 100%;
	}

	.banner {
		height: 100%;
		width: 100% !important;
		overflow: hidden;
		text-align: center;
		margin: 0 auto;
		padding: 0;
		z-index: 1;
	}

	.banner img {
		width: auto;
		margin: 0 -100%;
		max-width: none;
	}

	.center-row {
		background: white;
		box-shadow: 0px 8px 16px 0px rgba(28, 32, 44, 0.05);
		padding-left: 120px;
		padding-right: 120px;
		width: 1200px;
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	.content-box-gray {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		background: #F7F7FA;
	}



	.paoduct-item {
		width: 30%;
	}

	.introduce {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}
	.title-img {
		width: 300px;
		height: 65px;
	}
	// new start
	.xst-title {margin: 100px 0 10px 0;text-align: center;font-size: 40px;line-height: 48px;color: #122A88;}
	.xst-title-bottom {border: 1px solid #E7E9EF; position: relative;}
	.bottom-img img {width: 208px;height: 3px;position: absolute;left: 0;right: 0;margin: auto;top: -1px;}
	.xst-link-div {margin-top: 20px; width: 150px; height: 40px; border-radius: 1px; border: 1px solid #122A88; display: flex; justify-content: center; align-items: center;}
	.xst-link-mainPage {font-size: 16px;font-family: MicrosoftYaHei;color: #122A88;line-height: 21px;}
	// 公共样式结束-------------------------------------

	// 产品介绍开始-------------------------------
	.xst-product {padding-top: 30px;}
	.xst-product-ctrl {display: flex; height: 130px; width: 100%;background-color: #FFFFFF;}
	.xst-ctrl {position: relative; line-height: 30px; color:#122A88;display: flex;height: 130px;width: 240px;font-size: 14px;justify-content: center;align-items: center;flex-direction: column;border-right: solid 0.5px #eee;border-left: solid 0.5px #eee;cursor: pointer;}
	.xst-ctrl img {width: 56px; height: 56px;}
	.xst-ctrl-selected {background-color: #122A88; color: #fff;}
	.xst-ctrl-bottom{position: absolute;margin:auto;left: 0;right: 0;bottom: -18px;width: 16px;}
	.xst-ctrl-bottom img{width: 16px; height: 9px;}

	.xst-product-content{display: flex;align-items: center; flex-direction: column;justify-content: center;height: 346px;width: 100%;}
	.xst-product-bakimg1{background-image: url(../../assets/images/home/homepage-img-远程系统.png)}
	.xst-product-bakimg2{background-image: url(../../assets/images/home/homepage-img-安全监管系统.png)}
	.xst-product-bakimg3{background-image: url(../../assets/images/home/homepage-img-激光雷达系统.png)}
	.xst-product-bakimg4{background-image: url(../../assets/images/home/homepage-img-视频监控系统.png)}
	.xst-product-bakimg5{background-image: url(../../assets/images/home/homepage-img-NBIOT系统.png)}
	.xst-product-content-top{font-size: 32px;color: #fff;line-height: 90px;letter-spacing: 2px;font-family: MicrosoftYaHei;}
	.xst-product-content-main {font-size: 20px;font-family: MicrosoftYaHeiSemilight;color: #FFFFFF;line-height: 32px;letter-spacing: 1px;width: 80%; text-align: center;}
	// 产品介绍结束---------------------------------
	// 项目案例 start-----------------------------
	.xst-project{margin-top: 30px;}
	.xst-project-content{height: 322px;width: 100%; position: relative; margin-top: 10px;}
	.xst-project-back{height: 322px; width: 198px; background-color: #122A88; position: absolute; z-index: 0;}
	.xst-project-list{height: 322px; width: 1130px; position: absolute; z-index: 2;display: flex;align-items: center;}
	.xst-project-item{
		height: 268px; width: 268px;border-radius: 2px;cursor: pointer;
		.handleDialog{
			position: absolute;z-index: 5;top:27px;background: rgba(0, 0, 0, 0.6);height: 268px;width: 268px;border-radius:2px;
			.content{
				height: 180px;width: 100%;padding: 30px 20px 10px 20px;color: white; font-family: MicrosoftYaHei;
				.content-title{width: 100%; -webkit-line-clamp: 2; font-size: 20px; line-height: 28px;}
				.content-sub-title{ -webkit-line-clamp: 3; font-size: 14px; line-height: 20px; margin-top: 16px;}
			}
			.content-button{ cursor: pointer; font-size: 12px; width: 80px; height: 24px; border-radius: 1px; border: 1px solid #FFFFFF; color: #FFF; margin-left: 20px;display: flex;justify-content: center;align-items: center;}
		}
	}
	
	.xst-left-margin{margin-left: 15px;}
	.xst-right-margin{margin-right: 15px;}
	.xst-project-img{height: 268px;width: 268px;border-radius: 2px;}
	.xst-project-title-back{ position: absolute; top: 248px; height: 48px; background-color: #000; opacity: 0.5; width: 268px; color: white; border-radius: 0px 0px 2px 2px;
			z-index: 4; display: flex;align-items: center;padding-left: 14px}
	.xst-project-title{position: absolute; bottom: 40px; color: white;z-index: 56; padding-left: 15px;width: 250px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
	
	.project {
		height: 925px;display: flex;justify-content: center;
		// 案例悬浮
		.hover {display: unset;}
	}
	// 项目案例 end-------------------------------
	// 加工工厂 start ------------------------------------
	.factory {height: 920px;display: flex;justify-content: center;position: relative}
	.xst-carousel{margin-top: 40px;}
	.xst-factory-back{width: 100%;height: 335px;background-color: #122A88;position: absolute;top: 580px;color: #fff;padding-top: 190px; display: flex; align-items: center;flex-direction: column;}
	.xst-factory-title{font-size: 16px;line-height: 21px;color: #333333;font-family: MicrosoftYaHeiSemibold;margin-bottom: 8px;}
	.xst-factory-subTitle{font-size: 14px; line-height: 21px;-webkit-line-clamp: 3;color: #333;font-family: MicrosoftYaHei;margin-top: 10px;}
	.medium{background-color: #FFF;box-shadow: 0px 4px 10px 0px rgba(0, 15, 72, 0.6);border-radius: 2px;width: 400px;position: relative;cursor: pointer;}
	.mediumDftH{height: 530px;z-index:2;}
	.mediumSeltH{height: 590px;z-index:10;}
	.mediumDefaultImg{height: 380px;}
	.mediumSelectImg{height: 440px;}
	// 加工工厂 end -------------------------------------
	// 聚焦鑫昇腾 start ----------------------------------

	// 聚焦鑫昇腾 end ----------------------------------
	// 关于鑫昇腾
	.guanyu {height: 880px;	display: flex;justify-content: center;position: relative;}
	.xst-box1{ width: 900px;height: 394px;background: rgb(17, 42, 136);	position: absolute;
			top: -1px;margin: auto;	left: 0;right: 0;opacity: 0.5;z-index: 1;}
	.xst-box2{width: 800px;	height: 394px;background: rgb(17, 42, 136);	position: absolute;	top: 30px;margin: auto;	left: 0;right: 0;opacity: 0.6;z-index: 2;}
	.xst-box3{width: 710px;	height: 350px;background: rgb(17, 42, 136);	position: absolute;top: 60px;margin: auto;left: 0;right: 0;opacity: 0.7;z-index: 3;	}
	.xst-box4{width: 620px;	height: 350px;background: #FFF;	position: absolute;top: 95px;margin: auto;left: 0;right: 0;z-index: 5;}
	.xst-box5{width: 100%;height: 350px;background-color: #122A88;position: absolute;top: 530px;color: #fff;padding-top: 190px; display: flex; align-items: center;flex-direction: column;}
	.company-profile{width: 1150px;color: #fff;font-size: 14px; line-height: 24px;text-align: center;}
	// 关于鑫昇腾
	.position-relative {position: relative;	}

	.position-absolute {position: absolute;	}

	.gengduo {position: absolute;right: 70px;top: 90px;	}

	.gdBtn {
		display: flex;
		align-items: center;
		width: 54px;
		justify-content: space-between;

		img {
			height: 16px;
			width: 16px;
		}
	}

	.content {
		.case_title {
			padding: 1rem 0;
			display: flex;
			justify-content: space-around;
			align-items: center;
			padding: 0 30px;

			.icon {
				width: 1.5rem;
			}

			.case_name {
				font-size: 0.7rem;
				margin-left: 1rem;
				color: #363636;
			}
		}
	}

	.back_color {
		background-color: #ffffff;
		width: 105%;
		height: 105%;

		.case_title {
			padding: 1rem 0;
			display: flex;
			justify-content: center;
			align-items: center;

			.icon {
				width: 1.5rem;
			}

			.case_name {
				font-size: 0.7rem;
				margin-left: 1rem;
				color: #363636;
			}
		}
	}

	// 新闻 
	.news {
		height: 732px;
		display: flex;
		justify-content: center;
	}

	.news-content {
		padding: 40px 20px 0 20px;
		display: flex;
		justify-content: space-between;
	}

	.news-left {
		width: 460px;
		height: 470px;
	}

	.news-title {
		font-size: 16px;
		line-height: 22px;
		font-family: MicrosoftYaHeiSemibold;
		color: #333333;
	}

	.news-right {
		width: 660px;
		height: 470px;
	}

	.news-time {
		font-size: 12px;
		height: 21px;
		color: #999999;
		line-height: 22px;
		font-family: MicrosoftYaHei;
	}

	.news-des {
		font-size: 14px;
		line-height: 22px;
		height: 42px;
		text-overflow: ellipsis;
		padding: 0 10px 0 0;
		/* white-space: nowrap; */
		-webkit-line-clamp: 2;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		color: #333333;
	}

	.news-right-content {
		display: block;
		height: 110px;

		.content-body {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.border {
			border-bottom: 0.5px solid #e8e8e8;
		}
	}

	.news-right-content-left {
		width: 660px;
		height: 90px;
	}

	.news-right-content-right {
		width: 140px;
		height: 80px;
	}

	// 关于鑫昇腾


	.guanyu-detail {
		height: 425px;
		font-size: 14px;
		line-height: 22px;
		width: 560px;
		overflow: hidden;
		background-color: #fff;
		padding: 20px;

		p {
			text-indent: 2em;
			margin: 0 0 14px;
		}
	}

	// 合作客户
	.customer-body {
		height: 690px;
		display: flex;
		justify-content: center;

		.customer-list {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;

			img {
				margin-top: 40px;
				width: 920px;
				height: 490px;
			}
		}

	}

	// ---------------------------------------------------------------------------------
	.home {
		background: #F6F6F6;
		font-size: 14px;
		div, a{font-family: MicrosoftYaHei;}
		.head {
			background-color: #f5f5f5;
		}

		// 标题字体
		.title {
			margin-bottom: 2rem;
			.cn {
				font-size: 1.4rem;
			}

			.en {
				font-size: 1.15rem;
				color: #363636;
			}
		}

		.contenta {

			width: 1200px;
			//max-width: 1200px !important;

			//width: 90%;

			//background: url(../../assets/images/home/contenta.png);
			display: flex;
			background-size: 100%;
			flex-direction: column;

			// .product_back {
			// 	background: url(../../assets/images/case@3x.png);
			// }

			//首页新闻
			.news {
				display: flex;

				.news_left {
					width: 45%;

					.png {
						margin: 40px;
					}
				}

				.news_right {


					width: 55%;
					background: #fff;

					.news_title {
						width: 100%;
						display: flex;
						justify-content: space-between;
						align-items: center;

						.cn {
							font-size: 1.4rem;
						}

						.en {
							font-size: 1.15rem;
							color: #363636;
						}
					}

					.dr {
						border: 1px dotted #e8e8e8;
						margin: 35px 0 15px 0;
					}

					.news_list {
						height: 48rem;

						.list {
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: space-between;
							border-bottom: 1px solid #e8e8e8;

							.time {
								width: 4rem;

								.day {
									font-size: 1.2rem;
									text-align: center;
								}

								.month {
									font-size: 0.85rem;
									text-align: center;
									color: #636363;
								}
							}

							.image {
								width: 100px;

								img {
									width: 100%;
								}
							}
						}
					}
				}
			}

			// 主要客户
			.key {
				padding: 2rem;

				.customers {
					.li {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 3rem;

						.logo {
							width: 15%;
							background: #fff;
							padding: 1rem;

							.image {


								img {
									width: 100%;
								}
							}
						}
					}
				}
			}

			// 公司介绍
			.produce {
				display: flex;
				justify-content: center;
				// align-items: center;
				background: #fff;


				.produce_rt {
					width: 45%;
					padding: 1rem;
					font-size: 0.75rem;
					color: #363636;

					.paragraph {
						margin-top: 2rem;
						text-indent: 1rem;
					}
				}
			}
		}

		// 联系我们
		.contact {
			text-align: center;
			background: #fff;
			margin: -0.5rem 0;
			padding: 4rem 0;

			.text {
				font-size: 1.3rem;
				margin: 2rem 0;
				color: #363636;
			}

			.form {
				display: flex;
				border: 1px solid #b5b5b5;

				.form_lf {
					width: 50%;
					border-right: 1px solid #b5b5b5;

					.img_form1 {
						width: 2rem;
						margin: 2rem auto 0;
					}

					.submit {
						width: 50%;
						display: flex;
						// justify-content: center;
						margin: 0 auto;
						flex-direction: column;

						textarea {
							padding: 0.5rem;
							margin: 1rem 0;
							border: 1px solid #b5b5b5;
						}

						input {
							padding: 0.5rem;
							margin: 1rem 0;
							border: 1px solid #b5b5b5;
						}

						button {
							width: 7rem;
							margin: 1rem auto 2rem;
							border: unset;
							padding: 0.5rem 0;
							background: #145ab2;
							color: #fff;
						}
					}
				}

				.form_rt {
					width: 50%;

					.img_form2 {
						width: 2rem;
						margin: 2rem auto;
					}

					p {
						font-size: 1rem;
						color: #363636;
						line-height: 3rem;
					}

					button {
						width: 7rem;
						margin: 1rem auto 2rem;
						border: 1px solid #000;
						padding: 0.5rem 0;
						background: #fff;
						color: #000;
					}
				}
			}
		}

		// 弹窗
		.tanchuang {
			width: 34rem;
			position: fixed;
			top: 20%;
			left: 0;
			right: 0;
			margin: 0 auto;

			.sub {
				position: absolute;
				top: 0;
				right: 0;
				font-size: 2.5rem;
				padding: 0 1rem;
				color: #fff;
				cursor: pointer;
			}

			.suba {
				position: absolute;
				font-size: 1.2rem;
				text-align: left;
				line-height: 2.2rem;
				color: #fff;
				top: 50%;
				transform: translateY(-50%);
				right: 0;
				margin-right: 3rem;
			}

			.subb {
				position: absolute;
				bottom: 0;
				right: 0;
				margin-bottom: 1rem;

				button {
					background: unset;
					border: 0;
					margin-right: 1rem;
					width: 6rem;
					height: 2rem;
					font-size: 1rem;
				}

				.a {
					color: #fff;
				}

				.b {
					background: #fff;
					color: #192b83;
				}
			}
		}
	}

	.xuanFu {
		display: none;
		background: url(../../assets/images/index/z35.png);
		width: 400px;
		height: 200px;
		border: 1px solid #D4CD49;
		position: fixed;
		opacity: 0.7;
		left: 70%;
		top: 20%;
		z-index: 999 !important;

		.btn {
			width: 70px;
			height: 40px;
			align-items: flex-end;
			font-weight: 500;
		}

		.btn-null {
			color: white;
			border: 1px solid #fff;
		}

		.btn-current {
			color: blue;
			background-color: white;
		}
	}

	.xuanfuForm {

		width: 400px;
		background-color: white;
		border: 1px solid #D4CD49;
		position: fixed;
        z-index: 999 !important;
		left: 35%;
		top: 10%;
	}
	.xuan-icon{
		display: none;
		background: url(../../assets/images/index/脚手架官网_03.png);
		background-repeat: no-repeat;
		
		background-position: center;
		background-color: #22aae8;

				width: 60px;

				height: 60px;

				border: 1px solid #D4CD49;

				position: fixed;

				opacity: 0.7;

				left: 95%;

				top: 13%;

				z-index: 999 !important;
				 border-radius:35px;
	}
</style>

import notice from "maya-notice";

/**
 * HTTP错误处理
 * @param err axios错误对象
 */
function httpErrorHandler(err) {
    if (err.response && err.response.data) {
        if (err.response.data.message) {
            notice.error(err.response.data.message);
        } else {
            notice.error('服务器异常');
        }
    } else {
        if (err.message) {
            notice.error(err.message);
        } else {
            notice.error('服务器不可用，请稍后再试');
        }
    }
}

export default {
    httpErrorHandler,
}